import React, { useEffect } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from "gatsby"
// import { MdDescription } from 'react-icons/md'
// import {IoMdCheckmarkCircleOutline} from 'react-icons/io'
// import {RiShip2Line } from 'react-icons/ri'
import { FormattedMessage } from 'react-intl'
import Aos from 'aos'
import 'aos/dist/aos.css'


import { GiFingerPrint } from 'react-icons/gi'
import { HiOutlineHand } from 'react-icons/hi'
import { AiOutlineLock, AiOutlineCopyright } from 'react-icons/ai'
// import { AiOutlineCopyright } from 'react-icons/ai'
import { FaLaptopCode } from 'react-icons/fa'
import { ImLinkedin } from 'react-icons/im'


const Imp = () => {

    useEffect(() => {
        Aos.init({})
    }, [])

    const data = useStaticQuery(graphql`
      query {
        allFile(filter: {ext: {regex: "/(jpg)|(png)|(jpeg)/"}, name: {in: ["info-1", "info-2"]}}) {
          edges {
            node {
              childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `)

    return (
        <InfoContainer>
            <Description
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="1000">
            <FormattedMessage id="ImpressumText" />
            </Description>
            <ContentWrapper>
                <ColumnOne>
                    <Infos data-aos="fade-up"
                        data-aos-delay="150"
                        data-aos-duration="1200">
                        <h3> <GiFingerPrint /> Impressum </h3>
                        <p>Etihad Shipping Germany GmbH</p>
                        <p>Billstrasse 180</p>
                        <p>20539 Hamburg</p>
                        <p>Tel.: +49 (40) 73 09 13 97 0</p>
                        <p>Fax: +49 (40) 73 09 13 97 16</p>
                        <p>Email: info@etihadshipping.de </p>
                        <p>Geschäftsführer: Husam Hamdoun</p>
                        <p>Sitz der Gesellschaft: Hamburg</p>
                        <p>Amtsgericht Hamburg HRB 112869</p>
                        <p>USt-IdNr.: DE 269764189</p>
                        <br />
                        <p>Wir arbeiten ausschließlich aufgrund der Allgemeinen Deutschen Spedituersbedingungen (ADSp.) Geltend ist die jeweils neuste Fassung. Erfüllungsort und Gerichtsstand für beide Seiten ist Hamburg.</p>




                    </Infos>
                    <Infos data-aos="fade-up"
                        data-aos-delay="200"
                        data-aos-duration="1200">
                        <h3> <HiOutlineHand /> HAFTUNGSAUSSCHLUSS </h3>
                        <p>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich</p>
                    </Infos>

                    <Infos data-aos="fade-up"
                        data-aos-delay="150"
                        data-aos-duration="1200">
                        <h3> <AiOutlineLock /> DATENSCHUTZ </h3>
                        <p>
                            Die Nutzung unserer Webseite ist in der Regel ohne Angabe
                            personenbezogener Daten möglich. Soweit auf unseren Seiten
                            personenbezogene Daten (beispielsweise Name, Anschrift oder
                            eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich,
                            stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
                            ausdrückliche Zustimmung nicht an Dritte weitergegeben.</p>
                        <br />
                        <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
                            Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
                            nicht möglich.</p>
                        <br />
                        <p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten
                            durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und
                            Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber
                            der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten
                            Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
                        </p>

                    </Infos>
                    <Infos data-aos="fade-up"
                        data-aos-delay="200"
                        data-aos-duration="1200">
                        <h3> <AiOutlineCopyright /> URHEBERRECHT </h3>
                        <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht.
                            Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des
                            Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                            Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
                            beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
                            werden wir derartige Inhalte umgehend entfernen.</p>

                    </Infos>

                    <Infos data-aos="fade-up"
                        data-aos-delay="200"
                        data-aos-duration="1200">
                        <h3> <FaLaptopCode /> WEBSITE-ENTWICKLUNG </h3>
                        <p>Diese Website wurde von Akram Kadoura entwickelt. 
                        <SocialIconLink href="https://www.linkedin.com/in/akram-kadoura-5363176b/" target="_blank" aria-label="Linkedin"> <ImLinkedin/></SocialIconLink>
                         </p>
                       

                    </Infos>

                </ColumnOne>

            </ContentWrapper>
        </InfoContainer>
    )
}

export default Imp

const InfoContainer = styled.div`
    width: 100%;
    height: 100%;
    background: #edf2f5;
    color: #283951;
    padding: 2.8rem calc((100vw - 1300px) / 2);
`

const Description = styled.p`
    text-align: start;
    padding: 0 4rem;
    margin-bottom: 1.8rem;
    font-size: clamp(1.2rem, 5vw, 1.6rem);
    font-weight: bold;
    color: #283951;
    line-height: 2.2rem;
    /* width: 90%; */

    @media screen and (max-width: 768px) {
        padding: 0 2.8rem;
    }

`

const ContentWrapper = styled.div`
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
    padding: 0 2rem;

    @media screen and (max-width: 1100px) {
        grid-template-columns: 1fr;
    }

`

const ColumnOne = styled.div`
    /* display: grid;
    grid-template-rows: 1fr; */
    max-width: 100%;
    font-size: clamp(0.9rem, 4vw, 1.1rem);
    max-height: 98%;
`

const Infos = styled.div`
    padding-top: 1rem;
    padding-right: 2rem;
    padding-left: 2rem;
    margin-bottom: 2rem;

    h3 {
        margin-bottom: 1rem;
        font-size: 1.1rem;
        font-style: italic;
        color: #bd0b27;
        direction: ltr;
    }

    p {
        color: #283951;;
        direction: ltr;
        
    }
`


const ColumnTwo = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 1rem;
    grid-gap: 20px;
    max-height: 88%;

    @media screenn and (max-width: 500px) {
        grid-template-columns: 1fr;
    }
`

const Images = styled(Img)`
    border-radius: 5px;
    height: 100%;
`

const BottomLine = styled.div`
    height: 5px;
    width: 100%;
    display: block;
    background-color: #bd0b27;
    margin: 2rem auto 0 0;

    
    @media screen and (max-width: 1020px) {
            display:none;
    }
`

const SocialIconLink = styled.a`
    color: #283951;
    font-size: 24px;

    &:hover {
        color: #bd0b27;
        transition: 0.3s east-out;
    }
`