import React from "react"
import { FormattedMessage } from 'react-intl'
import { Layout } from '../components/common/Layout'
import Head from '../components/common/Head'
import SEO from "../components/seo"
import Explain from "../components/Explain"
import { ExplainDataOne} from "../data/ExplainData"
import Imp from "../components/Imp"
import Scroll from "../components/Scroll"
import "../components/layout.css"

const Impressum = () => (
  <Layout>
    <Scroll showBelow={250} />
    <Head title="ImpressumPageTitle" />
    <h1 className="impressum"><FormattedMessage id="Impressum" /></h1>
    <Imp/>
  </Layout>
)

export default Impressum